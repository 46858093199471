<template>
  <nav id="app">
    <a-config-provider :locale="locale">
      <router-view />
    </a-config-provider>
  </nav>
</template>

<script>
import zhCN from "ant-design-vue/es/locale/zh_CN"; //引入antd中文包
import 'dayjs/locale/zh-cn';
export default {
  beforeCreate() {
    // 在页面加载时读取sessionStorage
    if (sessionStorage.getItem("store")) {
      this.$store.replaceState({
        ...this.$store.state,
        ...JSON.parse(sessionStorage.getItem("store")),
      });
    }
    // 在页面刷新时将store保存到sessionStorage里
    window.addEventListener("beforeunload", () => {
      sessionStorage.setItem("store", JSON.stringify(this.$store.state));
    });
  },
  data() {
    return {
      locale: zhCN, //传值给a-config-provider组件
    };
  },
};
</script>

<style>
@import "main.less";

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#layout .ant-tabs .ant-tabs-bar {
  background: #fff;
  padding: 0 24px;
  overflow: hidden;
}
/* .table-proj {
  background: #fafafa;
} */
.table_proj {
  background: #fafafa;
}
.sub_table {
  height: 57px;
}
.ant-table td {
  white-space: nowrap;
}
.ant-tabs .ant-tabs-large-bar .ant-tabs-tab {
  font-size: 14px;
}
.ant-tabs .ant-tabs-large-bar .ant-tabs-tab {
  padding: 16px 16px 21px 16px;
}
/* .tag_text {
  padding-left: 24px;
  margin-top: 16px;
} */
</style>
