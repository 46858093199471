export default [ // 绩效中心
    {   // 绩效中心--绩效考核
        path: '/performance/asst',
        name: 'asst_index',
        component: (() => import('@/views/performance/index.vue')),
        meta: { title: "绩效中心", type: 'performance' },
        children: [
            {
                path: '/performance/asst/comp',
                name: 'comp',
                component: (() => import('@/views/performance/compchecksheet/index.vue')),
                meta: { title: "绩效中心", type: 'performance' },
            },
            {
                path: '/performance/asst/major',
                name: 'major',
                component: (() => import('@/views/performance/majorcheck/index.vue')),
                meta: { title: "绩效中心", type: 'performance' },
            },

            {
                path: '/performance/asst/dept',
                name: 'dept',
                component: (() => import('@/views/performance/deptcheck/index.vue')),
                meta: { title: "绩效中心", type: 'performance' },
            },
            {
                path: '/performance/asst/project',
                name: 'project',
                component: (() => import('@/views/performance/projectcheck/index.vue')),
                meta: { title: "绩效中心", type: 'performance' },
            },
        ]
    },
    {   //院绩效统计
        path: "/perFormance/merits/hospital_detail",
        name: "merits_hospitalDetail",
        component: (() => import('@/views/performance/merits/hospital_detail')),
        meta: { title: "绩效中心", type: 'none' },
    },
    {   //部门绩效统计
        path: "/performance/merits/dept_detail",
        name: "merits_deptDetail",
        component: (() => import('@/views/performance/merits/dept_detail.vue')),
        meta: { title: "绩效中心", type: 'none' },
    },
]