<template>
    <template v-if="type == '1'">
        <a-avatar v-if="gender == '1'" style="background-color: #1890ff">{{ user_nm.slice(-2) }}</a-avatar>
        <a-avatar v-else-if="gender == '2'" style="background-color: #87d068">{{ user_nm.slice(-2) }}</a-avatar>
        <a-avatar v-else :src="avatar" @click="handlerClick(avatar)" :loadError="(res) => res ? '' : loadError(user_sex)" />
    </template>
    <template v-else>
        <a-avatar v-if="gender == '1'" style="background-color: #1890ff">{{ user_nm.slice(-2) }}</a-avatar>
        <a-avatar v-else-if="gender == '2'" style="background-color: #87d068">{{ user_nm.slice(-2) }}</a-avatar>
        <a-avatar v-else :src="avatar" :loadError="(res) => res ? '' : loadError(user_sex)" />
    </template>
    
    <!-- 头像 -->
    <a-modal v-model:visible="imgModal" :footer="null" :closable="false" :width="220" centered
      @cancel="() => { imgModal = false }">
      <img :src="imgModal_img" style="width: 128px; height: 128px; margin: 24px;">
    </a-modal>
</template>

<script>
export default {
    name: 'mbr_avatar',
    props: {
        type: '',
        user_sex: '',
        user_nm: '',
        avatar: '',
    },
    components: {},
    data() {
        return {
            gender: '',
            imgModal: false,
            imgModal_img: '',
        };
    },
    methods: {
        loadError(sex) {
            this.gender = sex
        },
        getAvatar() {
            if (this.avatar != null && this.avatar != '') {
                this.gender = '0'
            } else {
                this.gender = this.user_sex
            }
        },
        handlerClick(avatar) { // 头像预览
            this.imgModal = true
            this.imgModal_img = avatar
        },
    },
    mounted() {
        this.getAvatar()
    },
    beforeMount() {},
}
</script>

<style>

</style>