export default [ // 通讯录管理
    {   // 部门管理
        path: '/mail/department',
        name: 'org-dept',
        component: (() => import('@/views/mail/department/index.vue')),
        meta: { title: '部门管理', type: 'mail_dept' },
    },
    {   // 部门管理--详情 main_index
        path: '/mail/department/details',
        name: 'dept_detail',
        component: (() => import('@/views/mail/department/dept_detail.vue')),
        meta: { title: '部门管理' },
    },
    {   // 企业微信
        path: '/mail/wechat',
        name: 'wechat_index',
        component: (() => import('@/views/mail/wechat/dept.vue')),
        meta: { title: '部门管理', type: 'mail_dept' },
    },
    {   // 员工管理
        path: '/mail/employee',
        name: 'org-mbr',
        component: (() => import('@/views/mail/employee/org-mbr.vue')),
        meta: { title: '员工管理', type: 'mail_mbr' },
    },
    {   // 员工管理--新建
        path: '/mail/employee/newindex',
        name: 'new_mbr',
        component: (() => import('@/views/mail/employee/new_mbr.vue')),
        meta: { title: '员工管理' },
    },
    {   //员工管理--企业微信
        path: '/mail/wechat/mbr',
        name: 'wechat_mbr',
        component: (() => import('@/views/mail/wechat/mbr.vue')),
        meta: { title: '员工管理', type: 'mail_mbr' },
    },
];