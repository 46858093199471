export default [ // 计算中心、合同管理、外部协作、订单管理
    {   // 计算中心 -- 面积列表
        path: '/areaprj',
        name: 'arearprj_index',
        component: (() => import('@/views/areaprj/index.vue')),
        meta: { title: '计算中心' },
    },
    {   // 计算中心 -- 面积列表
        path: '/areaprj/dateil',
        name: 'arearprj_detail',
        component: (() => import('@/views/areaprj/detail.vue')),
        meta: { title: '计算中心', type: 'none' },
    },
    {   //合同管理 -- 销售合同
        path: '/contractprj/list',
        name: 'contractprj_list',
        component: (() => import('@/views/contractprj/list/index.vue')),
        meta: { title: '销售合同' },
    },
    {   //合同管理 -- 采购合同
        path: '/contractprj/purchasing',
        name: 'contractprj_purchasing',
        component: (() => import('@/views/contractprj/purchasing/index.vue')),
        meta: { title: '采购合同', },
    },
    {   //合同管理 -- 合同模板
        path: '/contractprj/template',
        name: 'contractprj_template',
        component: (() => import('@/views/contractprj/template/index.vue')),
        meta: { title: '合同模板', },
    },
    // {   // 合同管理
    //     path: '/contractprj',
    //     name: 'contractprj',
    //     component: (() => import('@/views/contractprj/index.vue')),
    //     meta: { title: '合同列表', type: 'contractprj' },
    //     redirect: '/contractprj/list',
    //     children: [

    //     ]
    // },
    {   //外部协作--客户管理
        path: '/cooperation',
        name: "cooperation",
        component: (() => import('@/views/cooperation/client/index.vue')),
        meta: { title: "客户管理", type: 'clientList' },
        redirect: '/cooperation/clientList',
        children: [
            {   //外部协作--客户企业
                path: '/cooperation/clientList',
                name: "cooperation_clientList",
                component: (() => import('@/views/cooperation/client/list.vue')),
                meta: { title: "客户管理", type: 'clientList' }
            },
            {   //外部协作--客户联系人
                path: '/cooperation/clientContact',
                name: "cooperation_clientContact",
                component: (() => import('@/views/cooperation/client/contact.vue')),
                meta: { title: "客户管理", type: 'clientList' }
            },
        ],
    },
    {   //外部协作--供应商管理
        path: '/cooperation/vendorList',
        name: "cooperation_vendorList",
        component: (() => import('@/views/cooperation/vendor/index.vue')),
        meta: { title: "供应商管理", type: '' }
    },
    {   //订单管理--销售订单
        path: '/order/sale',
        name: "sale_index",
        component: (() => import('@/views/order/sale/index.vue')),
        meta: { title: "销售订单" }
    },
    {   //订单管理--销售订单
        path: '/order/purchase',
        name: "purchase_index",
        component: (() => import('@/views/order/purchase/index.vue')),
        meta: { title: "采购订单" }
    },
    {   //订单管理--采购订单
        path: '/order/supplier',
        name: "supplier_index",
        component: (() => import('@/views/order/supplier/index.vue')),
        meta: { title: "采购订单" }
    },
    {   //销售管理--商机管理
        path: '/order/oppt',
        name: "oppt_index",
        component: (() => import('@/views/order/oppt/index.vue')),
        meta: { title: "商机管理" }
    },
    {   //销售管理--跟单管理
        path: '/order/follow',
        name: "order_follow",
        component: (() => import('@/views/order/follow/index.vue')),
        meta: { title: "跟单管理" }
    },
    {   //销售管理--销售目标
        path: '/order/salesTarget',
        name: "salesTarget_index",
        component: (() => import('@/views/order/salesTarget/index.vue')),
        meta: { title: "销售目标" }
    },
];