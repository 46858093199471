import menu from "@/layout/data/menu";
import store from "@/store";
import { uniqueItems } from 'usuallyjs';
import { getSubMenu } from '@/views/topMenu/api'

const whiteList = [
    '/login',
    '/verification',
    '/404',
    '/test',
    '/403',
    '/403_1',
    '/refresh',
    '/no-permission',
    '/webCad',//webCad预览
    '/visualization/project', // 数据可视化
    '/setup',//账号设置
    '/wxbizlogin',//微信登录
    '/home/workspace/work', // 工作台--首页
    '/home/workspace/my_listview', // 工作台--日程
    '/home/workspace/news', // 工作台--消息
    '/home/workspace/performance', // 工作台--绩效
    '/home/workspace/performance/details',// 工作台--绩效--详情
    '/home/workspace/report', // 工作台--报表
    '/mail/wechat', // 部门管理--企业微信
    '/mail/department', // 部门管理--主数据
    '/mail/department/details', // 部门管理--详情
    '/mail/employee', // 员工管理
    '/mail/wechat/mbr',//员工管理--企业微信
    '/newprj', // 项目立项
    '/assignprj', // 项目指派
    '/claimprj', // 项目认领
    '/favorite', // 认领中心--收藏夹
    '/appointprj', // 项目分配
    '/order/follow',
    '/companproj_index', // 设计项目
    '/companproj_index/list', // 设计项目--项目列表
    '/companproj_index/partakeproj', //设计项目--我参与的项目
    '/companproj_index/responsibleproj', //设计项目--我负责的项目
    '/companproj_index/deptmentproj', //设计项目--我部门的项目
    '/companproj_index/deptmentsubproj', //设计项目--我下属部门的项目
    '/companproj_index/companyproj', //设计项目--我公司的项目
    '/companproj_index/sublist', // 设计项目--子项目列表
    '/designtask', // 设计任务
    '/ordertask', // 联系单
    '/library', // 项目库
    '/newsmanage/middle', // 消息管理--中台消息
    '/newsmanage/enter', // 消息管理--企业微信消息
    '/newsmanage/scene', // 消息管理--企业微信消息
    '/appcfg/businessdata', // 应用配置--应用数据--业务数据
    '/appcfg/businessdata/details',//业务数据--详情
    '/appcfg/filelabel', // 应用配置--文件标签
    '/appcfg/merit/details',//绩效数据--详情
    '/system/menu', // 系统管理--菜单管理
    '/system/api', // 系统管理--接口管理
    '/system/role', // 系统管理--角色管理
    '/performance/asst', // 绩效中心--绩效考核
    '/performance/compcheck', // 绩效中心--绩效考核
    '/performance/asst/comp', // 绩效中心--公司考核
    '/performance/asst/major', // 绩效中心--专业考核
    '/performance/asst/dept', // 绩效中心--部门考核
    '/performance/asst/project', // 绩效中心--项目考核
    '/performance/compcheck/detail', // 绩效中心--公司考核--详情
    '/performance/dptcheck/detail', // 绩效中心--部门考核--详情
    '/performance/projcheck/detail',//绩效中心--项目考核详情
    "/perFormance/merits/hospital_detail",//绩效中心--绩效统计--院绩效详情
    '/performance/merits/dept_detail',// 绩效中心--绩效统计--部门绩效详情
    '/ongoingprj', // 进行中项目
    '/filemgm', // 文件中心
    '/filemgm/template', // 文件中心--文件模板
    '/appcfg/shortcuts', // 应用配置--工作台配置--快捷方式
    '/appcfg/pends', // 应用配置--工作台配置--待办事项
    '/appcfg/projectsummary', // 应用配置--项目概要
    '/appcfg/projectevent', // 应用配置--项目事件
    '/areaprj/dateil', // 计算中心--面积列表详情
    '/contractprj/list', // 合同管理--合同列表
    '/contractprj/template', // 合同管理--合同模板
    '/contractprj/template/dateil', // 合同管理--合同模板--详情
    '/auditorder', // 质量中心--质量管理
    '/auditorder/initiate', // 质量中心--质量管理--我发起的图审单
    '/auditorder/drawproof', // 质量中心--质量管理--我校对的图审单
    '/auditorder/assign', // 质量中心--质量管理--我指派的图审单
    '/auditorder/process', // 质量中心--质量管理--我审核的图审单
    '/auditorder/drawseal', // 质量中心--质量管理--图纸盖章
    '/cooperation', // 外部协作--客户管理
    '/cooperation/clientList', // 外部协作--客户管理--客户企业
    '/cooperation/clientContact', // 外部协作--客户管理--客户联系人
    '/order/sale', // 订单管理--销售订单
    '/order/oppt',//销售管理--商机管理
    '/order/salesTarget',//销售管理--销售目标
    '/business/report', // 商业智能--智能报表
    '/business/report/menu', // 商业智能--智能报表
    '/business/report/power', // 商业智能--智能报表
    '/entersetup', // 企业设置
]; // 登陆页、404、refresh、no-permission等需要在白名单， demo这几个可以删

let dynamicWhiteList = [
    '/proj_home/403', // 项目首页无权限
    '/proj_home/dashboard', // 项目首页
    '/proj_home/progress', // 项目进度
    '/proj_home/member/design',// 设计成员
    '/proj_home/member/service',// 服务成员
    '/proj_home/projmember',//项目成员
    '/proj_home/contacts', // 联系人
    '/proj_home/design/req',  // 设计需求
    '/proj_home/design/task', // 设计任务
    '/proj_home/quality/in_audit', // 图纸内审
    '/proj_home/quality/ext_audit', // 图纸外审
    '/proj_home/quality/light_audit', // 图纸光评
    '/proj_home/quality/safe_audit', // 图纸安评
    '/proj_home/quality/seal', // 项目盖章
    '/proj_home/design/delivery', // 设计交付
    '/proj_home/design/arch', // 设计归档
    '/proj_home/design/arch/newindex', // 设计归档--新建
    '/proj_home/design/arch/editindex', // 设计归档--编辑
    '/proj_home/service/order', // 联系单
    '/proj_home/service/delivery', // 图纸交底
    '/proj_home/service/qa', // 施工答疑
    '/proj_home/service/inspection', // 现场巡检
    '/proj_home/service/acceptance', // 竣工验收
    '/proj_home/service/arch', // 服务归档
    '/proj_home/printing', // 项目出图
    '/proj_home/file', // 项目文件
    '/proj_home/startmeet',//项目启动会
    '/proj_home/endmeet',//项目结束会
    '/proj_home/reviewmeet',//项目复盘会
    '/proj_home/meeting', // 项目日程
    '/proj_home/event', // 项目事件
    '/proj_home/area',//项目面积
    '/proj_home/performance/projectpoints',//项目绩效--项目分
    '/proj_home/performance/buildinginfo',//项目绩效--楼栋信息
    '/proj_home/performance/treaty', // 项目绩效--协议变更
    '/proj_home/power', // 权限配置
    '/proj_home/settlement',//项目结算
    '/proj_home/activity',//项目活动
    '/proj_home/deliverymgt',//交付管理
    '/proj_home/resultmgt',//成果管理
    '/proj_home/order', // 关联订单

];

function getMenuList(_menus) {
    let _results = []
    if (_menus != null) {
        _menus.forEach(menu => {
            if (menu.path) {
                _results.push(menu.path);
            }
            if (menu.children) {
                _results = [..._results, ...getMenuList(menu.children)];
            }
        })
    }
    return _results;
};

export const havePermission = function (path) {
    // if (store.state.submenu.menus && store.state.user.token) {
    //     getSubMenu().then((res) => {
    //         store.dispatch('submenu/setMenus', res)
    //     })
    // }
    const _menus = uniqueItems([
        ...whiteList,
        ...getMenuList(store.state.user.menu),
        ...getMenuList(store.state.submenu.menus),
        ...dynamicWhiteList
    ])
    for (let _path of _menus) {
        if (_path === path) {
            return true
        }
    }
    return false;
};

// 动态添加白名单
// 比如详情按钮点击时，先执行addDynamicWhiteList('/detail'),再跳转
export const addDynamicWhiteList = function (path) {
    if (path) {
        dynamicWhiteList = uniqueItems([...dynamicWhiteList, path]);
    }
};

// 初始化动态白名单，登出切换用户时调用， vuex中logout已调用
export const initDynamicWhiteList = function () {
    dynamicWhiteList = [];
};