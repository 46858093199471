import { getToken, removeToken, setToken } from "@/utils/auth";
import { getUserInfo } from "@/api/user";
//import {initDynamicWhiteList} from "@/utils/permission";
// import { isSet } from "lodash";
import { uniqueItems } from "usuallyjs";
// import { message } from "ant-design-vue";
// 以cookie中token为准判断登陆状态，localStorage用来存储用户相关信息
const _token = getToken();
// console.log(_token);
const _user = JSON.parse(localStorage.getItem("user"));
let state = {
  token: "",
  login_nm: "", // 登录名
  avatar: "", // 头像；为空根据用户性别显示默认图像
  name: "", // 用户昵称
  status: "", // 用户帐号状态
  locked: "", // 用户帐号是否被锁定
  acct_nm: "", // 用户所属部门
  mob: "", // 用户手机
  org_nm: "", // 用户所属公司
  gender: "", // 性别。1：男；2：女;0：未知
  menu: [],
  dynamicRoute: [],
  //dynamicRoute: []  //动态路由
};
if (_user && _token === _user.token) {
  state = _user;
} else {
  removeToken();
  localStorage.removeItem("user");
}

const mutations = {
  SET_USERINFO: (state, userInfo) => {
    Object.keys(userInfo).forEach((key) => {
      state[key] = userInfo[key];
    });
    state.dynamicRoute = [];
  },
  ADD_DYNAMIC_ROUTE: (state, path) => {
    state.dynamicRoute = uniqueItems([...state.dynamicRoute, path]);
  },
  INIT_DYNAMIC_ROUTE: (state) => {
    state.dynamicRoute = [];
  },
};

const actions = {
  login({ commit }, token) {
    let _user = {
      token,
    };
    commit("SET_USERINFO", _user);
    return new Promise((resolve) => {
      getUserInfo((userinfo) => {
        let _menus = JSON.stringify(userinfo.menus);
        _menus = _menus.replace(/uri/g, "path");
        // _menus = _menus.replaceAll(","children":[]", ",");
        _menus = JSON.parse(_menus);
        _user = {
          ..._user,
          login_nm: userinfo.login_nm, // 登录名
          status: userinfo.status, // 用户帐号状态
          locked: userinfo.locked, // 用户帐号是否被锁定
          acct_nm: userinfo.acct_nm, // 用户所属部门
          mob: userinfo.mob, // 用户手机
          org_nm: userinfo.org_nm, // 用户所属公司
          gender: userinfo.gender, // 性别。1：男；2：女;0：未知
          avatar: userinfo.avatar, // 头像；为空根据用户性别显示默认图像
          name: userinfo.nm, // 用户昵称
          menu: _menus,
          dynamicRoute: [],
        };
        commit("SET_USERINFO", _user);
        setToken(token);
        localStorage.setItem("user", JSON.stringify(_user));
        resolve();
      });
    });
  },
  logout({ commit, state, dispatch }) {
    return new Promise((resolve) => {
      commit("SET_USERINFO", {
        token: "",
        login_nm: "", // 登录名
        avatar: "", // 头像；为空根据用户性别显示默认图像
        name: "", // 用户昵称
        status: "", // 用户帐号状态
        locked: "", // 用户帐号是否被锁定
        acct_nm: "", // 用户所属部门
        mob: "", // 用户手机
        org_nm: "", // 用户所属公司
        gender: "", // 性别。1：男；2：女;0：未知
        menu: [],
        dynamicRoute: [], //动态路由
      });
      removeToken();
      //initDynamicWhiteList();
      localStorage.removeItem("user");
      resolve();
    });
  },
  addDynamicRoute({ commit }, path) {
    commit("ADD_DYNAMIC_ROUTE", path);
  },
  initDynamicRoute({ commit }) {
    commit("INIT_DYNAMIC_ROUTE");
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
