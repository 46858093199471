export default [ // 文件中心、商业智能
    {   // 文件中心--文件列表
        path: '/filemgm',
        name: 'file_index',
        component: (() => import('@/views/filemgm/list/index.vue')),
        meta: { title: '文件中心', type: 'filemgm' },
    },
    {   // 文件中心--文件模板
        path: '/filemgm/template',
        name: 'template_index',
        component: (() => import('@/views/filemgm/template/index.vue')),
        meta: { title: '文件中心', type: 'filemgm' },
    },
    {   // 商业智能--智能报表
        path: '/business/report',
        name: 'report_index',
        component: (() => import('@/views/business/report/index.vue')),
        meta: { title: '智能报表', type: 'business' },
        redirect: '/business/report/menu',
        children: [
            {
                path: '/business/report/menu',
                name: 'report_menu_index',
                component: (() => import('@/views/business/report/list/contents.vue')),
                meta: { title: '智能报表', type: 'business' },
            },
            {
                path: '/business/report/power',
                name: 'report_power_index',
                component: (() => import('@/views/business/report/list/power.vue')),
                meta: { title: '智能报表', type: 'business' },
            }
        ]
    },
]