import store from "@/store";
import Emitter from "tiny-emitter"
import { $get } from "@/utils/request"
// 事件广播器
export const emitter = new Emitter();

// 键盘点击事件
export const listenKeyDownFunc = function ({ keyCode, callback }) {
  document.onkeydown = function (e) {
    if (e.keyCode === keyCode) {
      callback();
    }
  };
};

// 获取权限
export const getAuth = function (path) {
  if (store.state.user.menu && findMenu(path, store.state.user.menu)) {
    return findMenu(path, store.state.user.menu).auth;
  }
  return false;
};

// 标题
export const getMenuTitle = function (path) {
  if (store.state.user.menu && findMenu(path, store.state.user.menu)) {
    return findMenu(path, store.state.user.menu).title;
  }
  if (store.state.submenu.menus && findMenu(path, store.state.submenu.menus)) {
    return findMenu(path, store.state.submenu.menus).title;
  }
  return false;
};

function findMenu(path, menus) {
  for (const _m of menus) {
    if (_m.path) {
      const _path = `${(_m.path.indexOf("/") < 0 || _m.path.indexOf("/") > 1) ? "/" : ""}${_m.path}`
      if (_path === path) {
        return _m;
      }
    }
    if (_m.children && findMenu(path, _m.children)) {
      return findMenu(path, _m.children);
    }
  }
}

// 权限验证
//menu 菜单 系统菜单为false(从store里获取),项目菜单为[]
//action 操作权限的key值  用来判断是否有权限
export const hasAction = function (menu, action, proj_type) {
  //现在
  let menus = [];
  if (menu == false) {
    menus = store.state.user.menu;
  } else {
    if (proj_type != "library") {
      menus = menu;
    } else {
      return false
    }
  }
  return searchTree(menus, action)
}

export const searchTree = (nodes, searchKey) => {
  //searchKey为需要查询的key
  for (let _i = 0; _i < nodes.length; _i++) {
    if (nodes[_i].key === searchKey) {
      return true
    } else {
      if (nodes[_i].children && nodes[_i].children.length > 0) {
        let res = searchTree(nodes[_i].children, searchKey);
        if (res) {
          return res
        }
      }
    }
  }
  return false
}

// 当前菜单展开
export const MenuKey = function (path) {
  let menus = [];
  if (path.indexOf("/proj_home/") > -1) {
    menus = store.state.user.menu
    for (const _m of menus) {
      if (_m.path === path) {
        return menus.indexOf(_m)
      } else {
        if (_m.children) {
          for (const _mc of _m.children) {
            if (_mc.path == path) {
              return _m.key
            }
          }
        }

      }
    }
  } else {
    menus = store.state.user.menu
    for (const _m of menus) {
      if (_m.children) {
        for (const _mc of _m.children) {
          if (_mc.path == path) {
            return _m.key
          } else {
            if (_mc.children) {
              for (const _mcc of _mc.children) {
                if (_mcc.path == path) {
                  return _m.key
                }
              }
            }
          }
        }
      }
    }
  }
}

// 关键字/标题
export const MenuTitle = function (path, res) {
  let menus = [];
  if (res != undefined) {
    for (const _m of res) {
      if (_m.path === path) {
        return _m.title
      } else {
        if (_m.children) {
          for (const _mc of _m.children) {
            if (_mc.path == path) {
              return _mc.title
            } else {
              if (_mc.children) {
                for (const _mcc of _mc.children) {
                  if (_mcc.path == path) {
                    return _mcc.title
                  }
                }
              }
            }
          }
        }
      }
    }
  } else {
    menus = store.state.user.menu
    for (const _m of menus) {
      if (_m.children) {
        for (const _mc of _m.children) {
          if (_mc.path == path) {
            return _mc.title
          } else {
            if (_mc.children) {
              for (const _mcc of _mc.children) {
                if (_mcc.path == path) {
                  return _mc.title
                }
              }
            }
          }
        }
      }
    }
  }
}