<template>
    <!-- <a-dropdown v-if="list.length > 0 && power.home_info" placement="bottomRight" trigger="click"> -->
    <a-dropdown v-if="list.length > 0 && power.home_info" placement="bottomRight">
        <template #overlay>
            <a-menu>
                             <!-- :disabled="id == item.id || sub_id == item.id || item.is_exist == 'f'" -->
                <a-menu-item v-for="item in list" :key="item.id"
                             :disabled="id == item.id || sub_id == item.id"
                             @click="switchProj(item)">
                    <div style="display: flex;">
                        <span style="width: 185px; text-overflow: ellipsis; overflow: hidden;">{{ item.nm }}</span>&nbsp;&nbsp;
                    <template v-if="item.status < 20000" style="font-weight: lighter;">
                        <a-tag color="blue" v-if="item.status == '10000'">待立项</a-tag>
                        <a-tag color="blue" v-else-if="item.status == '10020'">待指派</a-tag>
                        <a-tag color="blue" v-else-if="item.status == '10040'">指派中</a-tag>
                        <a-tag color="blue" v-else-if="item.status == '10050'">待分配</a-tag>
                        <a-tag color="blue" v-else-if="item.status == '10051'">分配中</a-tag>
                        <a-tag color="blue" v-else-if="item.status == '10060'">进行中</a-tag>
                        <a-tag color="orange" v-else-if="item.status == '19996'">已暂停</a-tag>
                        <a-tag color="green" v-else-if="item.status == '19997'">项目入库</a-tag>
                        <a-tag color="#f5222d" v-else-if="item.status == '19998'">项目中止</a-tag>
                        <a-tag color="#52C41A" v-else-if="item.status == '19999'">项目完成</a-tag>
                    </template>
                    <template v-else style="font-weight: lighter;">
                        <a-tag color="blue" v-if="item.status == '20000'">待指派</a-tag>
                        <a-tag color="blue" v-else-if="item.status == '20020'">待认领</a-tag>
                        <a-tag color="blue" v-else-if="item.status == '20040'">设计待分配</a-tag>
                        <a-tag color="#108ee9" v-else-if="item.status == '20060'">设计分配中</a-tag>
                        <a-tag color="blue" v-else-if="item.status == '20080'">设计待开始</a-tag>
                        <a-tag color="#108ee9" v-else-if="item.status == '20100'">设计中</a-tag>
                        <a-tag color="blue" v-else-if="item.status == '20120'">服务待分配</a-tag>
                        <a-tag color="#108ee9" v-else-if="item.status == '20140'">服务分配中</a-tag>
                        <a-tag color="blue" v-else-if="item.status == '20160'">服务待开始</a-tag>
                        <a-tag color="#108ee9" v-else-if="item.status == '20180'">服务中</a-tag>
                        <a-tag color="blue" v-else-if="item.status == '29990'">设计完成</a-tag>
                        <a-tag color="#52C41A" v-else-if="item.status == '29991'">项目完成</a-tag>
                        <a-tag color="orange" v-else-if="item.status == '29996'">已暂停</a-tag>
                        <a-tag color="#f5222d" v-else-if="item.status == '29999'">项目中止</a-tag>
                        <a-tag color="#f5222d" v-else-if="item.status == '29998'">撤回项目</a-tag>
                    </template>
                </div>
                </a-menu-item>
            </a-menu>
        </template>
        <a-button style="font-size: 14px">
            切换项目
            <DownOutlined />
        </a-button>
    </a-dropdown>
</template>
<script setup>
import { hasAction } from '@/utils'
import { getSubMenu } from "@/views/topMenu/api";
import { DownOutlined } from "@ant-design/icons-vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from 'vue-router'
import { onMounted, onBeforeMount, reactive, ref, toRefs } from 'vue';
//切换项目
const props = defineProps({
    list: Array, // 项目数据
    type: String // 类型
})
const emits = defineEmits(['getData'])
const { list, type } = toRefs(props)
const id = ref(JSON.parse(localStorage.getItem("query")).id)
const sub_id = ref(JSON.parse(localStorage.getItem("query")).sub_id)
const store = useStore()
const state = store.state
const route = useRoute();
const router = useRouter();
const query = route.query;
const power = reactive({
    home_info: "", // 切换项目
})
const switchProj = (item) => {
    if (item.type == "proj") {
        localStorage.setItem(
            "query",
            JSON.stringify({
                id: item.id,
                title: item.proj_nm,
                proj_type: query.proj_type,
                page: query.page,
                perpage: query.perpage,
                value: query.value,
            })
        );
        store.dispatch("layout/changeType", "prjspace");
        store.dispatch("layout/changeTitle", item.title);
        router.push({
            name: "project_home",
            query: {
                id: item.id,
                status: item.status,
                title: item.proj_nm,
                proj_type: query.proj_type,
                page: query.page,
                perpage: query.perpage,
                value: query.value,
            },
        });
    } else if (item.type == "sub") {
        localStorage.setItem(
            "query",
            JSON.stringify({
                sub_id: item.id,
                title: item.proj_nm,
                proj_type: query.proj_type,
                page: query.page,
                perpage: query.perpage,
                value: query.value,
            })
        );
        store.dispatch("layout/changeType", "prjspace");
        store.dispatch("layout/changeTitle", item.title);
        store.dispatch("layout/changeDirector", item.proj_duty_nm);
        router.push({
            name: "project_home",
            query: {
                sub_id: item.id,
                status: item.status,
                title: item.proj_nm,
                proj_type: query.proj_type,
                page: query.page,
                perpage: query.perpage,
                value: query.value,
            },
        });
    }
}
const handlerPower = () => {
    getSubMenu().then((res) => {
        power.home_info = hasAction(res, "projspace-switch-proj"); // 切换项目
    })
}
const handlerList = () => {
    $get({
        url: "projhome/home_info",
        params: {
            proj_id: JSON.parse(localStorage.getItem("query")).id,
            proj_sub_id: JSON.parse(localStorage.getItem("query")).sub_id,
            major_id: JSON.parse(localStorage.getItem("query")).major_id,
        },
        workspace: true,
    }).then((res) => {
        let result = res.data;
        let proj_list = [];
        let sub_list = [];
        if (result.proj_list.length > 0) {
            proj_list = result.proj_list;
            proj_list[0].type = "proj";
        }
        if (result.sub_list.length > 0) {
            sub_list = result.sub_list;
            for (let i = 0; i < sub_list.length; i++) {
                sub_list[i].type = "sub";
            }
        }
        list = proj_list.concat(sub_list);
    }).catch((err) => {
        console.log(err);
    });
}
onBeforeMount(() => {
    handlerPower()
})
</script>
<style lang="less">

</style>