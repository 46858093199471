import { emitter } from "@/utils";

const state = {
  menus: [],
};

function changeMenuParams(menus, menuPath, params) {
  for (let i = 0; i < menus.length; i++) {
    if (menus[i].path === menuPath) {
      menus[i].params = params;
      return menus;
    }
    if (menus[i].children) {
      return changeMenuParams(menus[i].children, menuPath, params);
    }
  }
}

const mutations = {
  SETMENUS: (state, menus) => {
    state.menus = menus;
  },
};

const actions = {
  setMenus({ commit }, menus) {
    commit("SETMENUS", menus);
  },
  changeMenuParams({ commit, state }, { menuPath, params }) {
    const _menus = changeMenuParams(state.menus, menuPath, params)
    commit("SETMENUS", _menus);
    emitter.emit("update-submenu")
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
