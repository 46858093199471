export default [ // 项目管理中心
    {   // 项目管理中心--项目立项
        path: '/newprj',
        name: 'newprj',
        component: (() => import('@/views/projmana/project/index.vue')),
        meta: { title: '项目立项' },
    },
    {   // 项目管理中心--项目指派
        path: '/assignprj',
        name: 'assignindex',
        component: (() => import('@/views/projmana/assign/index.vue')),
        meta: { title: '项目指派' },
    },
    {   // 项目管理中心--项目认领
        path: '/claimprj',
        name: 'claimin',
        component: (() => import('../views/projmana/claim/index.vue')),
        meta: { title: "项目认领" },
    },
    {   // 项目管理中心--项目认领--收藏夹
        path: '/favorite',
        name: 'favorite',
        component: (() => import('@/views/projmana/claim/favorites.vue')),
        meta: { title: '收藏夹' },
    },
    {   // 项目管理中心--项目分配
        path: '/appointprj',
        name: 'distrindex',
        component: (() => import('@/views/projmana/appointprj/index.vue')),
        meta: { title: '项目分配' },
    },
    {   // 项目库
        path: '/library',
        name: 'libraryindex',
        component: (() => import('@/views/library/index.vue')),
        meta: { title: '项目库' },
    },
];