export default [ // 应用配置
    {   // 应用配置--应用数据--业务数据
        path: '/appcfg/businessdata',
        name: 'businessdata',
        component: (() => import('@/views/appcfg/businessdata/index.vue')),
        meta: { title: '应用数据', type: 'appcfg' },
    },
    {   // 应用配置--应用数据--业务数据--详情
        path: '/appcfg/businessdata/details',
        name: 'businessdetail',
        component: (() => import('@/views/appcfg/businessdata/detail.vue')),
        meta: { title: '应用数据' },
    },
    {   // 应用配置--应用数据--绩效数据--详情
        path: '/appcfg/merit/details',
        name: 'meritdetail',
        component: (() => import('@/views/appcfg/merit/detail.vue')),
        meta: { title: '应用数据' },
    },
    {   // 应用配置--文件标签
        path: '/appcfg/filelabel',
        name: 'appcfg_filelabel',
        component: (() => import('@/views/appcfg/filelabel/index.vue')),
        meta: { title: '文件标签', type: 'appcfg' },
    },
    {   // 应用配置--工作台配置
        path: '/appcfg/workbench_config',
        name: 'workbench_config',
        component: (() => import('@/views/appcfg/workbench_config/index.vue')),
        meta:{ title: '工作台配置', type: 'workbench_config' },
        redirect:'/appcfg/shortcuts',
        children:[
            {   // 应用配置--快捷方式
                path: '/appcfg/shortcuts',
                name: 'shortcut',
                component: (() => import('@/views/appcfg/workbench_config/shortcut/index.vue')),
                meta: { title: '工作台配置' },
            },
            {   // 应用配置--待办事项
                path: '/appcfg/pends',
                name: 'pend',
                component: (() => import('@/views/appcfg/workbench_config/pend/index.vue')),
                meta: { title: '工作台配置' },
            },
        ]
    },
    {   // 应用配置--项目概要
        path: '/appcfg/projectsummary',
        name: 'projectsummary',
        component: (() => import('@/views/appcfg/projectsummary/index.vue')),
        meta: { title: '项目概要' },
    },
    {   // 应用配置--项目事件
        path: '/appcfg/projectevent',
        name: 'projectevent',
        component: (() => import('@/views/appcfg/projectevent/index.vue')),
        meta: { title: '项目事件' },
    },
];