export default [
    {   //系统管理
        path: '/system',
        name: 'system',
        component: (() => import('@/views/system/index.vue')),
        meta: { type: "system" },
        redirect: '/system/role',
        children: [
            {   // 角色管理
                path: '/system/role',
                name: 'system-sole',
                component: (() => import('@/views/system/role/index.vue')),
                meta: { title: "角色管理", type: 'system' },
            },
            {   // 菜单管理
                path: '/system/menu',
                name: 'system-menu',
                component: (() => import('@/views/system/menu/index.vue')),
                meta: { title: "菜单管理", type: 'system' },
            },
            {   // 接口管理
                path: '/system/api',
                name: 'system-api',
                component: (() => import('@/views/system/api/index.vue')),
                meta: { title: "接口管理", type: '' },
            },
            {   // 操作日志
                path: '/system/log',
                name: 'system-log',
                component: (() => import('@/views/system/log/index.vue')),
                meta: { title: "操作日志", type: '' },
            },
        ],
    },
];