import Cookies from "js-cookie";

const TokenKey = "Authorization";

export function getToken() {
  const token = Cookies.get(TokenKey) || "";
  return token;
}

export function setToken(token) {
  return Cookies.set(TokenKey, token, { expires: 1 });
}

export function removeToken() {
  return Cookies.remove(TokenKey);
}
