import {$req} from "@/utils/request";
import { message } from 'ant-design-vue';

export const getSubMenu = function (typ, id) {
  let query = JSON.parse(localStorage.getItem('query'))
  return new Promise((resolve) => {
    $req({
      requestType: 'get',
      requestUrl: 'auth/getTopMenu',
      requestParams: {
        project_id: typ == '1' ? id : query.id,
        idType: '0',
      },
    }).then(res => {
      resolve(res.data);
    }).catch(err => {
      message.error(err, 4);
    })
  })
}